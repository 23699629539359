:root {
  --mainColor: #1c2974;
  --secondColor: #269cda;
  --textColor: #2e3242;
  --lightGrey: #eaedf0;
  --milkyWhite: rgb(247, 247, 247);
  --danger: #ff5869;
  --font: Sohne, sans-serif;
}

/* Scroll bars */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 6px;

}

::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}

/* Body */
body{
  font-family: var(--font);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.ia_span {
  font-weight: bold;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main_view {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  font-family: "SUSE", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.nav_bar {
  min-height: 80px;
  max-height: auto;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  background-color: white;
  border-bottom: 1px solid lightgray;
  color: black;
  z-index: 100;
}

.nav_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  height: 100%;
}

.logo {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: start;
}

.logo_cont_script{
  font-size: 50px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: bold;
}

.logo_cont_script p{
  margin-bottom: 0px;
  font-weight:lighter;
  color: var(--mainColor);
  border-bottom: 3px solid var(--secondColor);
}

.logo_cont{
  display: flex;
  flex-direction: row;
  align-items: start;
  width: auto;
  overflow: hidden;
  border-radius: 10px;
  height: 55px;
  width: 200px;
}

.logo_main{
  background-image: url("../public/IMG/logo.jpg");
  height: 100%;
  width: 100%;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Body */
.body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}


/* First Section: Image */
.section_image {
  height: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 150px;
}

.img_back {
  background-image: url("../public/IMG/pool_villa.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 98%;
  height: 98%;
  margin: auto;
  border-radius: 20px;
}

.punchline_phrase {
  color: var(--mainColor);
  position: absolute;
  border: 2px solid white;
  font-size: 70px;
  text-align: start;
  color: white;
  width: 30%;
  margin-left: 68%;
  margin-top: 400px;
  border-radius: 20px;
  padding: 20px;
  backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
  background: rgba(1, 1, 1, 0.1); /* Optional: Add a semi-transparent background to enhance readability */
}

.punchline_phrase p {
  margin-bottom: 10px;
}

.more_btn {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  font-size: 20px;
  color: white;
  border-radius: 5px;
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.form_desc {
  width: 38%;
  height: 100%;
  border: 1px solid lightgray;
  border-radius: 10px;
}

/* Second Section: Two Divs in a Row */
.section_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 500px;
  width: 80%;
  margin: auto;
  margin-bottom: 150px;
}

.box {
  text-align: center;
  width: 46%;
  border-radius: 20px;
  box-shadow: 2px 2px 5px 2px whitesmoke;
  border: 1px solid lightgray;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
}

.box_title {
  font-size: 30px;
  width: 100%;
  font-weight: bold;
}

.img_box {
  height: 200px;
  width: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  margin-bottom: 75px;
}


.img_1 {
  background-image: url("../public/IMG/sell_house.jpg");
}

.img_2 {
  background-image: url("../public/IMG/look_price_house.jpg");
}

.main_cont_box {
  width: 95%;
  /* border: 1px solid lightgray;
  background-color: whitesmoke; */
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.box_btn {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
}

/* Third Section: Form */
.main_form{
width: 100%;
}

.section_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 50%;
  margin: auto;
  margin-bottom: 150px;
  padding: 10px;
  border-radius: 10px;

}

form {
  width: 100%;
  margin: auto;
}

.form-row {
  width: 100%;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.input {
  flex: 1;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 2px whitesmoke;
}

.textarea {
  width: 100%;
  padding: 10px;
  height: 150px;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 2px whitesmoke;
}

.submit-button {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  border-radius: 5px;
  color: white;
}

.title_form {
  width: 100%;
  color: var(--mainColor);
  font-weight: bold;
  margin: auto;
  font-size: 25px;
  margin-bottom: 5px;
}


.footer {
  height: auto;
  padding-top: 10px;
  padding-bottom: 20px;
  width: 100%;
  background-color: white;
  border-top: 1px solid lightgray;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link_footer{
  font-weight: bold;
  cursor: pointer;
  height: 20px;
  width: 100%;
}

.footer p {
  margin-bottom: 0px;
  font-size: 15px;
}

/* Posts */
.container_card {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 50px;
}

.filter_cont {
  width: 30%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
}

.filter_card {
  width: 90%;
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 2px whitesmoke;
  margin: auto;
  margin-top: 100px;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
}

.filter_title {
  font-size: 25px;
  width: 95%;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 15px;
  /* font-weight: bold; */
}

.input_card_modale {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter_labels {
  font-size: 15px;
  width: 95%;
  margin: auto;
  margin-bottom: 2px;
}

.filter_btn {
  width: 95%;
  padding: 10px;
  cursor: pointer;
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  border-radius: 5px;
  color: white;
  height: 45px;
  margin: auto;
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.filter_input {
  width: 95%;
  margin: auto;
  height: 45px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 15px;
  color: gray;
}

.filter_input_file {
  width: 95%;
  margin: auto;
  height: auto;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 15px;
  color: gray;
}

.filter_input:focus {
  outline: none;
}

.card_container {
  width: 70%;
  height: 100%;
  padding-top: 100px;
}

.card_post {
  width: 95%;
  border: 1px solid lightgray;
  height: 250px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 2px whitesmoke;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.img_cont {
  width: 30%;
  height: 100%;
  overflow: hidden;
  border-right: 1px solid lightgray;
  cursor: pointer;
}

.img_wrapper {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  height: 100%;
}

.img_shown {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex: 0 0 auto;
  margin: auto;
}

.scrol_btn_cont {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.scroll_button {
  background: var(--mainColor);
  border: none;
  cursor: pointer;
  z-index: 1;
  padding: 5px;
  height: 100%;
  margin-top: 10px;
  border-radius: 5px;
  color: white;
  width: 50px;
}

.scroll_button.left {
  left: 0;
}

.scroll_button.right {
  right: 0;
}


.info_cont {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price_row {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.btn_cont_price {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.price_btn_change {
  height: 30px;
  width: 50px;
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  border-radius: 5px;
  margin-right: 10px;
}

.price_btn_delete {
  height: 30px;
  width: 50px;
  border: 1px solid var(--danger);
  background-color: var(--danger);
  color: white;
  border-radius: 5px;
  margin-right: 10px;
}

.price_btn_publish {
  height: 30px;
  width: auto;
  border: 1px solid green;
  background-color: green;
  color: white;
  border-radius: 5px;
  margin-right: 10px;
}

.price_btn_unpublish {
  height: 30px;
  width: auto;
  border: 1px solid darkorange;
  background-color: darkorange;
  color: white;
  border-radius: 5px;
  margin-right: 10px;
}

.sell_rent {
  padding-left: 10px;
  margin-bottom: 0px;
  height: 50%;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  width: 98%;
}

.sell_rent p{
  margin-bottom: 0px;
}

.sell_rent i{
  height: 100%;
  text-align: start;
  padding-top: 5px;
}

.price {
  height: 50%;
  padding-left: 10px;
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 30px;
  display: flex;
  flex-direction: row;
  align-items: start;
}

.det_row {
  height: 50%;
  width: 100%;
}

.details_desc_title {
  padding-left: 10px;
  margin-bottom: 0px;
  font-weight: bold;
  height: 20%;
  display: flex;
  flex-direction: row;
  align-items: end;
}

.details_desc {
  padding: 10px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: start;
  overflow-y: scroll;
  height: 100px;
}

.existing-images{
  width: 100%;
  height: fit-content;
  border-radius: 10px;
}

.img_cont_prev{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.img_prev{
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  border-radius: 10px;
  width: fit-content;
  margin-right: 10px;
  padding: 10px;
  height: 100px;
  overflow: hidden;
}

.remove_img_btn{
  background-color: var(--danger);
  border: 1px solid var(--danger);
  color: white;
  border-radius: 5px;
  font-size: 12px;
}

.link_prev_img_cont{
  width: 150px;;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--lightGrey);
  padding: 5px;
  font-size: 12px;
  background-color: var(--lightGrey);
  border-radius: 5px;
  margin-right: 5px;
}

.link_prev_img {
  width: 90px;
  overflow: hidden;
  white-space: nowrap;   /* Prevent text from wrapping */
  text-overflow: ellipsis; /* Show ellipsis when text overflows */
}

.img_link_cont{
  width: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Agency */
.agency_cont {
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 90vh;
}

.agency_img_cont {
  width: 48%;
  border: 1px solid lightgray;
  box-shadow: 2px 2px 5px 2px whitesmoke;
  border-radius: 10px;
  height: 50%;
  overflow: hidden;
}

.agency_img {
  background-image: url("../public/IMG/logo.jpg");
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.agency_desc {
  width: 48%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.agency_desc_title {
  font-size: 35px;
  margin-bottom: 10px;
  height: 30%;
  display: flex;
  flex-direction: row;
  align-items: end;
  font-weight: bold;
}

/* Register Initial page */
.register_page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.register_conts_reg,
.register_conts {
  width: 100%;
  height: 100%;
}

.reg_cont {
  margin: auto;
  margin-top: 100px;
  width: 450px;
  height: auto;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 200px 10px 1000px 1px var(--mainColor);
  border: 2px solid var(--lightGrey);
  margin-bottom: 10px;
}

.btn_a_start {
  width: 100%;
  height: 40px;
  /* border-radius: 10px; */
}

.start_btn {
  border: 1px solid var(--lightGrey);
  font-size: 15px;
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  color: white;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.register_form_first {
  padding: 10px;
  border: 1px solid var(--lightGrey);
  height: 40px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 2px var(--lightGrey);
  width: 100%;
  margin-bottom: 20px;
  color: var(--textColor);
  font-size: 18px;
}

.register_form_first:focus {
  outline: none;
}

.register_form_first::placeholder {
  font-size: 13px;
  color: rgb(184, 181, 181);
}

.persona_reg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.person_reg {
  width: 49%;
}

.password_eye {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  justify-content: space-between;
}

.show_passowrd {
  margin-bottom: 0px;
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  height: 40px;
  width: 15%;
  border-radius: 5px;
  margin-left: 5px;
  font-size: 15px;
  border: 2px solid var(--lightGrey);
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.phone_number {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.phone_number select {
  width: 15%;
  margin-bottom: 20px;

  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  height: 40px;
  border-radius: 5px;
  margin-left: 5px;
  font-size: 15px;
  border: 2px solid var(--lightGrey);
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.phone_number input {
  width: 83%;
}

.already_present {
  font-size: 12px;
  text-align: start;
  width: 100%;
  margin-bottom: 0px;
}

.already_present span {
  color: var(--mainColor);
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.mini_logo {
  margin-bottom: 0px;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Spinner and errors */
.spinner_cont {
  width: 100%;
  text-align: center;
}

#spinner {
  color: var(--mainColor);
  font-size: 15px;
}

.error_message {
  color: var(--danger);
  font-size: 15px;
  font-weight: bold;
}

/* Dashboard */
.dashBoard_cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav_main{
  display: flex;
  padding: 15px;
  border-bottom: 2px solid var(--secondColor)
}

.nav_main_container{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
}

.nav_main_container:last-child{
  flex-direction: row-reverse;
}

.logout_btn{
  color: var(--danger);
  font-size: 15px;
  margin-bottom: 0px;
  cursor: pointer;
}

.dropdown-toggle{
  color: gray !important;
  text-decoration: none !important;
  margin-bottom: 0px !important;
}

.annunci_disponibili {
  margin-bottom: 0px;
  margin-right: 10px;
  padding: 5px;
  border-radius: 5px;
  color: white;
  background-color: var(--mainColor);
  border: 2px solid var(--mainColor);
  cursor: pointer;
  font-size: 15px;
}


.main_cont {
  height: 100%;
  width: 100%;
  /* border: 1px solid red; */
}


.manage_posts_cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  height: 100%;
  min-height: 80vh;
}

.function_bar {
  margin-top: 30px;
  background-color: var(--lightGrey);
  border: 1px solid lightgray;
  box-shadow: 2px 2px 5px 2px var(--milkyWhite);
  width: 90%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  margin-bottom: 50px;
  justify-content: space-between;
}

.function_bar p{
  font-weight: bold;
  margin-bottom: 0px;
  margin-right: 10px;
}

.add_post_btn {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  height: 100%;
  color: white;
  border-radius: 10px;
}

.post_container {
  /* min-height: 10vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* Contacts */
.manage_contacts_cont{
  height: 100vh;
  margin: auto;
  width: 95%;
  margin-top: 20px;
}

.dropdown-basic{
  border: 1px solid red !important;
}

.contact_modal_btn,
.contact_modal_btn_del{
  font-size: 15px;
  border-radius: 5px;
}

.contact_modal_btn{
  color: var(--textColor);
  background-color: transparent;
  border: 1px solid transparent;
}


.contact_modal_btn_del{
  color: white;
  background-color: var(--danger);
  border: 1px solid var(--danger);
  transition: all 0.3s ease-in;
}

.contact_modal_btn_del:hover{
  color: var(--danger);
  background-color: white;
  border: 1px solid var(--danger);
}

/* Modal contact */
.modal_body{
  width: 100%;
  text-align: start;
}

.confirm_modal_title{
  font-size: 25px;
  font-weight: bold;
}

/* Modal */
.close_modal_btn {
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  padding: 5px;
  border-radius: 5px;
}

.save_modal_btn {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  padding: 5px;
  border-radius: 5px;
}

/* Contacts */
.services-content {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
}

.services-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.service-item {
  border: 2px solid var(--lightGrey);
  color: var(--secondColor);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out; /* Smooth transition */
}

.icon-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon-text-container i {
  margin-right: 10px;
  font-size: 20px;
}

.icon-text-container p {
  margin-bottom: 0px;
}

.form-container {
  width: 40%;
  height: fit-content;
  padding: 20px;
  border: 2px solid var(--lightGrey);
  border-radius: 10px;
  margin-top: 50px;
}

/* Add styling for the active service item */
.service-item.active {
  background-color: var(--secondColor);
  color: white;
  box-shadow: 2px 2px 10px 2px var(--secondColor);
}

.powered_by_fj{
  margin-top: 10px;
  font-size: 12px !important;
  font-weight: bold;
}

.privacy_cont{
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-bottom: 20px;
}

.privacy_cont label{
  margin-left: 5px;
  margin-bottom: 0px;
}

.privacy_policy_page{
  width: 98%;
  margin: auto;
}

.no_posts{
  width: 100%;
  text-align: center;
}


/* Img modal */
.modal-fullscreen {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-fluid {
  max-width: 100%;
}

.close_img_btn{
  background-color: var(--lightGrey);
  color: var(--textColor);
  border: 1px solid var(--lightGrey);
}

.img_body{
  display: flex;
  flex-direction: column;
}

.btn_cont{
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 866px) {

  .logo_cont_script{
    font-size: 38px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: bold;
  }

  /* First Section: Image */
  .punchline_phrase {
    color: var(--mainColor);
    position: absolute;
    border: 2px solid white;
    font-size: 35px;
    text-align: start;
    color: white;
    width: 95%;
    margin: auto;
    margin-top: 500px;
    margin-left: 10px;
    border-radius: 20px;
    padding: 20px;
    backdrop-filter: blur(10px); 
    background: rgba(1, 1, 1, 0.1); 
  }

  .section_row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    width: 100%;
    margin: auto;
    margin-bottom: 100px;
  }

  .box {
    text-align: center;
    width: 90%;
    margin: auto;
    border-radius: 20px;
    box-shadow: 2px 2px 5px 2px whitesmoke;
    border: 1px solid lightgray;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  /* Third Section: Form */
  .section_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 98%;
    margin: auto;
    margin-bottom: 150px;
    padding: 10px;
  }

  form {
    width: 95%;
    margin: auto;
  }

  .form-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

  .title_form {
    width: 95%;
    margin: auto;
    font-size: 25px;
    margin-bottom: 5px;
  }


  /* Posts */
  .container_card {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .filter_cont {
    width: 95%;
    margin: auto;
    height: auto;
    position: relative;
  }

  .filter_card {
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 2px whitesmoke;
    margin: auto;
    height: 70%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-content: center;
    justify-content: space-between;
  }

  .card_container {
    width: 95%;
    margin: auto;
    /* border: 1px solid black; */
    height: 100%;
    padding-top: 100px;
  }

  .card_post {
    width: 100%;
    border: 1px solid lightgray;
    height: 800px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 2px whitesmoke;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
  }

  .img_cont {
    width: 100%;
    height: 60%;
    overflow: hidden;
    border-bottom: 1px solid lightgray;

  }

  .info_cont {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }

  .price_row {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .det_row {
    /* display: none; */
  }

  .details_desc_title {
    /* display: none; */
  }

  .details_desc {
    /* display: none; */
  }

  /* Agency */
  .agency_cont {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 800px;
    margin-top: 10px;
  }

  .agency_img_cont {
    width: 100%;
    border: 1px solid lightgray;
    box-shadow: 2px 2px 5px 2px whitesmoke;
    border-radius: 10px;
    height: 200px;
    padding: 10px;
    overflow: hidden;
    margin-bottom: 50px;
  }

  .agency_desc {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 150px;
  }

  .agency_desc_title {
    font-size: 35px;
    margin-bottom: 10px;
    height: 10%;
    display: flex;
    flex-direction: row;
    align-items: end;
    font-weight: bold;
  }

  /* Login */
  .main_frame {
    width: 100%;
  }

  .register_page {
    width: 95%;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .register_conts_reg,
  .register_conts {
    width: 100%;
    height: 100%;
  }

  .reg_cont {
    margin: auto;
    margin-top: 100px;
    width: 100%;
    height: auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 200px 10px 1000px 1px var(--mainColor);
    border: 2px solid var(--lightGrey);
    margin-bottom: 10px;
  }

  .services-content {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
    align-content: center;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .services-container {
    width: 98%;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .form-container {
    width: 98%;
    margin: auto;
    margin-bottom: 20px;
    height: fit-content;
    padding: 10px;
    border: 2px solid var(--lightGrey);
    border-radius: 10px;
    margin-top: 5px;
  }
  
  /* Add styling for the active service item */
  .service-item.active {
    background-color: var(--secondColor);
    color: white;
    box-shadow: 2px 2px 10px 2px var(--secondColor);
  }

}